import { Link } from 'gatsby'
import { kebabCase } from 'lodash'
import cx from 'classnames'
import React from 'react'
import { CalendarOutlined, TagOutlined } from '@ant-design/icons'
import { Post, Site } from '../types'
import { Button } from 'antd'

interface PostExcertProps {
  post: Post
  site: Site
  isPost?: boolean
  summary?: boolean
}

export function PostsExcert({
  posts,
  site,
  isPost = true
}: {
  posts: Post[]
  site: Site
  isPost?: boolean
}): JSX.Element {
  return (
    <ol className="post-list">
      {posts.map(post => (
        <li key={post.fields.slug}>
          <PostExcert
            key={post.id}
            site={site}
            post={post}
            summary={true}
            isPost={isPost}
          />
        </li>
      ))}
    </ol>
  )
}

export function PostExcert(props: PostExcertProps): JSX.Element {
  const { post, site, summary = false, isPost = true } = props

  const content = post.html || post.excerpt

  const title = summary ? (
    <Link to={post.fields.slug} itemProp="url">
      {post.frontmatter.title}
    </Link>
  ) : (
    post.frontmatter.title
  )

  return (
    <article className={cx('blog-post', { full: !summary })}>
      <header>
        <h2>{title}</h2>
        {isPost && (
          <ul className="post-meta">
            <li>By {site.siteMetadata.author.name}</li>
            <li>
              <CalendarOutlined /> {post.frontmatter.date}
            </li>
            <li>
              in{' '}
              {post.frontmatter.categories.map(c => (
                <Link to={`/categories/${kebabCase(c)}`} key={c}>
                  {c}
                </Link>
              ))}
            </li>
          </ul>
        )}
      </header>
      <section>
        <div
          className="content"
          itemProp="content"
          dangerouslySetInnerHTML={{ __html: content }}
        />
        {(summary && (
          <div className="read-more">
            <Button type="primary" size="large">
              <Link to={post.fields.slug} itemProp="url">
                Read more
              </Link>
            </Button>
          </div>
        )) || <TagButtons tags={post.frontmatter.tags || []} />}
      </section>
    </article>
  )
}

const TagButtons: React.FC<{ tags: string[] }> = ({ tags }) => {
  return (
    <div className="post-tags">
      {tags.map((item: string) => (
        <Button key={item} className="tag-btn">
          <TagOutlined />
          <Link key={item} to={`/tags/${kebabCase(item)}`}>
            {item}
          </Link>
        </Button>
      ))}
    </div>
  )
}
